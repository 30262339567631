import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import logo from "../img/logo-black.svg";
import scrollTo from "gatsby-plugin-smoothscroll";
import EnquiryForm from "../components/enquiry";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import ContactUsBanner from "../img/contact-us.jpg";
export default function ContactUs() {
  const { loaded, error, formCreated } = useHubspotForm({
    region: "na1",
    portalId: "19573488",
    formId: "1bc4eac9-1a8c-476e-8e4f-58cff8896631",
    target: "#my-hubspot-form-new",
  });
  return (
    <div>
      <Helmet>
        <title>OIC - Terms & Conditions</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Layout>
        <div style={{ padding: "90px 0", background: "#000" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <h1
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    color: "#fff",
                  }}
                >
                  Terms & Conditions
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row ">
              <br></br>
              <br></br>
              <p>
                BEFORE ACCEPTING THESE TERMS AND CONDITIONS, PLEASE READ THEM
                CAREFULLY. DO NOT USE THE SERVICES IF YOU DO NOT AGREE TO THESE
                TERMS AND CONDITIONS.
              </p>
              <br />
              <p>
                This document contains information explaining your rights to
                access and use OICApp (as defined below) and constitutes a
                binding agreement between you and OIC.
              </p>
              <h1>&nbsp;</h1>
              <h1>Terms &amp; Conditions</h1>
              <p>
                You agree to abide by all of the terms and conditions set out on
                our site and apps, as well as all relevant laws and regulations,
                by using our site and apps. However, we reserve the right to
                change or adjust the terms and conditions at any time and
                without notice. Before using CMS Solutions' facilities, please
                read the terms and conditions carefully.
              </p>
              <p>Grant of Licence/Intellectual Property</p>
              <br />
              <p>
                Your right to use the OIC is limited by the terms set forth in
                these T&amp;C.
              </p>
              <p>
                You acknowledge that all Intellectual Property Rights in
                OICmobile Apps are owned by or licensed to OIC. This material is
                made accessible to You as part of the Services and remains the
                property of OIC (and/or Our licensor). You do not gain any
                rights to such material. The Services are protected by
                applicable Intellectual Property laws.
              </p>
              <p>
                Under no circumstances may You use the Intellectual Property
                Rights without Our prior written consent.
              </p>
              <br />
              <h3>ADDITIONAL RESTRICTIONS</h3>
              <h4>WITHOUT OUR PRIOR PERMISSION, YOU MAY NOT:</h4>
              <ul>
                <li>
                  <p>
                    Frame the Site, pop-up windows over its pages, or otherwise
                    interfere with its show.
                  </p>
                </li>
                <li>
                  <p>For any purpose, create a deep connection to the Site.</p>
                </li>
                <li>
                  <p>
                    Allow, allow, or endorse the transmission of unsolicited
                    commercial advertisements or solicitations through e-mail in
                    any way (spam).
                  </p>
                </li>
                <li>
                  <p>
                    Use any high volume, automated, or electronic means
                    (including without limitation robots, spiders, scripts, or
                    other automatic devices) to access the Site or monitor or
                    copy our web pages or the Site Content.
                  </p>
                </li>
              </ul>
              <h4>YOU AGREE NOT TO:</h4>
              <ul>
                <li>
                  &ldquo;hack,&rdquo; &ldquo;crack,&rdquo; or otherwise attempt
                  to circumvent any copy protection, access control, or
                  license-enforcement mechanisms associated with or related to
                  the Site or Site Content.
                </li>
                <li>
                  <p>
                    Interfere with or disrupt the Site or servers or networks
                    connected to the Site, or disobey any requirements,
                    procedures, policies, or regulations of networks connected
                    to the Site;
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>
                Any Apps available for download from the Site are the
                proprietary work of OIC or other third parties indicated (the
                &quot;Software&quot;). The OIC is licensed rather than sold, and
                it is governed by the OIC Technology that comes with it. OIC
                accepts no liability for Third-Party Software used by
                InventoryPlus and makes it available to you AS IS, without
                warranty of any sort. OIC would not be liable to you for any
                direct, indirect, consequential, incidental, exceptional, or
                other damages of any kind relating to the Third-Party Software.
              </p>
              <h3>Disclaimer :</h3>
              <p>
                The website's materials, content, and documents, as well as
                software, goods, and services, may not be copied, transmitted,
                or distributed. The site and all content, materials, documents,
                software, goods, and services provided on the site are provided
                on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis
                unless otherwise stated. The site's content is permitted without
                guarantees, endorsements, or representations of any kind. Users
                do not have the ability to change or remove the site's content.
                It means that guarantees or endorsements of any kind are not
                limited to title, accuracy, product merchantability, or
                non-infringement, with the exception of warranties (if any) that
                cannot be excluded under applicable law. OIC makes no such
                guarantee about any web material that you access, download, or
                purchase from the site, and you use it at your own risk. OIC
                assumes no responsibility for any damage to your computer device
                or data loss that may occur as a result of downloading or using
                any material from the website.
              </p>
              <p>&nbsp;</p>
              <h3>Data Security:</h3>
              <p>
                Personal information provided via this website, whether by email
                or other means, will be used exclusively by OIC and will not be
                shared with third parties. Please contact us if you want to see,
                alter, or delete your personal information.
              </p>
              <p>&nbsp;</p>
              <p>Modification and Amendment</p>
              <br />
              <p>
                You acknowledge that you have read, understand, and consent to
                be bound by these Terms and Conditions, as modified by Us from
                time to time. We reserve the right to change these terms and
                conditions, or any part of them, at any time. If we make such a
                move, we will post a note in the OIC.
              </p>
              <br />
              <p>
                It is Your duty to review these Terms and Conditions on a
                regular basis to ensure that You adhere to them, and Your
                continued use of the Services will be considered Your approval
                of any changes to these Terms and Conditions. Do not start or
                continue to use the Services if you do not support these T&amp;C
                after an amendment.
              </p>
              <br />
              <p>Privacy</p>
              <br />
              <p>
                We need to collect and use your information in order to provide
                our services. The Privacy Policy describes how we gather, use,
                and exchange data. It also discusses how you can manage the data
                in a variety of ways. To use OIC, you must adhere to the terms
                of the privacy policy.
              </p>
              <br />
              <h3>Modifications to these Terms:</h3>
              <p>
                We reserve the right to change these Terms or our privacy policy
                at any time without providing you with prior notice or duty. Any
                of our goods and services, as well as their prices and details,
                are subject to change or discontinuation without warning.
              </p>
              <p>&nbsp;</p>
              <p>Questions</p>
              <br />
              <p>
                If You have concerns about these Terms and Conditions, please
                contact Us at marketing@oicapps.com.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
